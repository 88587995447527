<template>
  <div>
    <new-ticket />
  </div>
</template>

<script>
import newTicket from '@/components/custom/forms/newTicket'

export default {
  components: {
    newTicket,
  },
}
</script>

<style>

</style>
