<template>
  <div class="ticket-request">
    <h4>
      Opprett en sak
    </h4>
    <a-form @submit.prevent="submitForm">
      <a-form-item label="Kategori" required>
        <a-select
          :filter-option="filterOption"
          option-filter-prop="children"
          v-model="data.category"
          @change="onOptionChange('category', $event)"
          >
          <a-select-option v-for="category in categories" :key="category.value" :value="category.value">
            {{ category.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item v-for="field in customFields" :key="field.id" :label="field.title">
        <a-input v-model="extraFields[field.id]" :placeholder="field.title" v-if="field.type === 'text'" />
        <a-date-picker v-model="extraFields[field.id]" :placeholder="field.title" v-else-if="field.type === 'date'"/>
        <a-week-picker v-model="extraFields[field.id]" :placeholder="field.title" v-else-if="field.type === 'week'"/>
        <a-month-picker v-model="extraFields[field.id]" :placeholder="field.title" v-else-if="field.type === 'month'"/>
        <a-textarea v-model="extraFields[field.id]" :placeholder="field.title" :rows="4" v-else-if="field.type === 'textarea'"/>
        <a-select
          v-else-if="field.type === 'select'"
          :filter-option="filterOption"
          option-filter-prop="children"
          v-model="extraFields[field.id]"
          @change="onOptionChange(field.id, $event)"
          >
          <a-select-option v-for="option in field.options" :key="option.value" :value="option.value">
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Emne" required>
        <a-input v-model="data.subject" required />
      </a-form-item>
      <a-form-item label="Beskrivelse" required>
        <a-textarea v-model="dialog.content" required :rows="4"/>
      </a-form-item>
      <a-form-item>
        <a-button type="primary" html-type="submit">Send</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'
import moment from 'moment'

export default {
  data() {
    return {
      data: {
        subject: '',
        description: '',
      },
      customFields: [],
      categories: [],
      dialog: {
        content: '',
      },
      extraFields: {},
    }
  },
  methods: {
    submitForm() {
      // Handle form submission here
      console.log(this.data)
      console.log(this.extraFields)
      // convert all moment objects to string
      Object.keys(this.data).forEach(key => {
        if (this.data[key] && this.data[key]._isAMomentObject) {
          this.data[key] = this.data[key].format('YYYY-MM-DD')
        }
      })
      // sendt by, sendt date and status
      this.data.sentBy = this.$store.state.user.id
      this.data.sentByName = this.$store.state.user.name
      this.data.sentDate = String(moment().format('YYYY-MM-DDTHH:mm:ss'))
      this.data.status = 'open'
      this.data.access = [this.$store.state.user.id, this.data.category]
      this.dialog.owner = {
        id: this.$store.state.user.id,
        name: this.$store.state.user.name,
        avatar: this.$store.state.user.avatar,
      }
      this.dialog.time = String(moment().format('YYYY-MM-DDTHH:mm:ss'))
      let ticketRef = ''
      firestore.collection('helpdeskTickets').add(this.data)
        .then(ref => {
          ticketRef = ref.id
          console.log('Document written with ID: ', ref)
          if (this.extraFields) {
            let message = 'Ekstra Informasjon:\n\n'
            Object.entries(this.extraFields).forEach(([key, value]) => {
              const title = this.customFields.find(field => field.id === key).title
              message += `${title}: ${value}\n`
            })
            // prepend message to dialog
            this.dialog.content = message + '\n\n' + this.dialog.content
          }
          console.log('Dialog:', this.dialog)
          ref.collection('dialog').add(this.dialog)
          openNotification('Sak opprettet')
        })
        .then(() => {
          // need to add custom data as dialog before this
          this.$emit('newTicketCreated', ticketRef)
        })
        .catch(err => {
          openNotification('Noe gikk galt')
          console.error(err)
        })
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )
    },
    onOptionChange(ref, value) {
      console.log(ref, value)
      firestore.collection('settings/helpdesk/categories').doc(value).get()
        .then(doc => {
          const data = doc.data()
          this.customFields = data.fields
          // set default values for custom fields
          this.customFields.forEach(field => {
            if (field.default) {
              this.extraFields[field.id] = field.default
            } else {
              this.extraFields[field.id] = ''
            }
          })
        })
      // this.data[ref] = value
    },
  },
  mounted() {
    // get ticket settings from firestore
    firestore.collection('settings/helpdesk/categories').get()
      .then(snapshot => {
        snapshot.forEach(doc => {
          const category = {
            value: doc.id,
            label: doc.data().name,
            path: doc.ref,
          }
          this.categories.push(category)
        })
      })
  },
}
</script>

<style scoped>
.ticket-request {
  max-width: 600px;
  margin: 0 auto;
}
</style>
